import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { FormData, NewsletterFormSender } from 'app/utils/NewsletterFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  image?: ImageProps
  languageCode: string
  onNewsletterFormSendTo: NewsletterFormSender
  variant?: Variant
}

export const Newsletter = memo(function Newsletter({
  image,
  languageCode,
  onNewsletterFormSendTo,
  variant = 'default',
}: Props) {
  /**
   * MailUp configurations
   */

  let mailUpList: string = '1'
  let mailUpGroup: string = '7'

  switch (languageCode) {
    case 'de-DE':
      mailUpList = '4'
      mailUpGroup = '23'
      break
    case 'en-US':
      mailUpList = '4'
      mailUpGroup = '24'
      break

    default:
      break
  }

  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onNewsletterFormSendTo(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)
        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Newsletter Form',
          })
      }
    },
    [isSendingForm, onNewsletterFormSendTo],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container className="newsletter" variant={variant}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="ipadVertical">
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Media>

        <Form
          onSubmit={isSendingForm ? undefined : onSubmit}
          noValidate
          autoComplete={'off'}
        >
          <Rumble />
          <Spinner className={isSendingForm ? undefined : 'hidden'} />
          <Title>{useVocabularyData('newsletter-title', languageCode)}</Title>
          <Subtitle>
            {useVocabularyData('newsletter-subtitle', languageCode)}
          </Subtitle>
          <Wrapper row wrap space="between">
            <Input
              type="hidden"
              value={mailUpList}
              required={true}
              {...register('list', {
                required: true,
              })}
            />
            <Input
              type="hidden"
              value={mailUpGroup}
              required={true}
              {...register('group', {
                required: true,
              })}
            />
            <Input
              type="hidden"
              value="off"
              required={true}
              {...register('confirm', {
                required: true,
              })}
            />
            <Field>
              <Input
                color={variant === 'default' ? 'dark' : 'default'}
                error={formState.errors.campo1 !== undefined}
                isDirty={formState.dirtyFields.campo1}
                placeholder={`${useVocabularyData('name', languageCode)} *`}
                type="text"
                required={true}
                {...register('campo1', {
                  required: true,
                  validate: (campo1) => campo1.length > 2,
                })}
              />
            </Field>
            <Field>
              <Input
                color={variant === 'default' ? 'dark' : 'default'}
                error={formState.errors.campo2 !== undefined}
                placeholder={`${useVocabularyData('lastname', languageCode)} *`}
                type="text"
                required={true}
                {...register('campo2', {
                  required: true,
                  validate: (campo2) => campo2.length > 2,
                })}
              />
            </Field>
            <Field>
              <Input
                color={variant === 'default' ? 'dark' : 'default'}
                error={formState.errors.email !== undefined}
                isDirty={formState.dirtyFields.email}
                placeholder={`${useVocabularyData('email', languageCode)} *`}
                type="email"
                required={true}
                {...register('email', {
                  required: true,
                  validate: (email) => EMAIL_REGEX.test(email),
                })}
              />
            </Field>

            {variant === 'default' ? (
              <Media lessThan="ipadVertical">
                <Checkboxes className="checkbox">
                  <Checkbox
                    error={formState.errors.privacy_policy !== undefined}
                    label={useVocabularyData('form-policy-text', languageCode)}
                    isNewsletter
                    required={true}
                    {...register('privacy_policy', {
                      required: true,
                    })}
                  />
                </Checkboxes>
              </Media>
            ) : null}

            {variant === 'simple' ? (
              <Checkboxes className="checkbox">
                <Checkbox
                  error={formState.errors.privacy_policy !== undefined}
                  label={useVocabularyData('form-policy-text', languageCode)}
                  isNewsletter
                  required={true}
                  {...register('privacy_policy', {
                    required: true,
                  })}
                  variant="dark"
                />
              </Checkboxes>
            ) : null}

            <Input
              name="submit"
              type="submit"
              value={useVocabularyData('subscribe', languageCode)}
              variant="submit"
            />
          </Wrapper>

          {variant === 'default' ? (
            <Media greaterThanOrEqual="ipadVertical">
              <Checkboxes>
                <Checkbox
                  error={formState.errors.privacy_policy !== undefined}
                  label={useVocabularyData('form-policy-text', languageCode)}
                  isNewsletter
                  required={true}
                  {...register('privacy_policy', {
                    required: true,
                  })}
                />
              </Checkboxes>
            </Media>
          ) : null}
        </Form>

        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}

        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData(
              'newsletter-confirmation-message',
              languageCode,
            )}
            title={useVocabularyData(
              'newsletter-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Form = styled.form`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin: auto;
  padding: 4.375rem 10.347vw 4.875rem;
  transform: translateY(3.75rem);
  position: relative;
  z-index: 2;

  input[type='submit'] {
    height: 3.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border: none;
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0;
    &:hover {
      background: ${({ theme }) => theme.colors.variants.secondaryLight};
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  > svg {
    width: auto;
    height: 2.5rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    position: absolute;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1023px) {
    padding: 4.375rem 2rem 3.75rem;
    transform: translateY(0);

    input[type='submit'] {
      width: 100%;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.0813rem;
  line-height: 2.5rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 1.75rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  margin-top: 0.4375rem;
  text-align: center;

  @media (max-width: 1023px) {
    line-height: 1.625rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const Field = styled.div`
  flex: 1;
  margin-right: 0.125rem;

  input {
    height: 3.125rem;
    font-size: 0.8125rem;
    font-weight: 400;
    padding: 0 1.0625rem;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
    flex-basis: 100%;
    margin-bottom: 0.125rem;
  }
`

const Checkboxes = styled.div`
  text-align: center;
`

const Container = styled.div<ContainerProps>`
  padding: 5rem 11.944vw 0;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.4)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding: 0;

    .checkbox {
      margin-top: 1.25rem;
      margin-bottom: 1.875rem;

      > div {
        margin-top: 0;
      }
    }
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'simple':
        return css`
          margin-top: 1.25rem;
          padding: 0;
          &:before {
            display: none;
          }

          ${Title} {
            display: none;
          }

          ${Subtitle} {
            color: ${theme.colors.variants.neutralDark4};
            line-height: 2.0625rem;
            text-align: left;
          }

          ${Form} {
            background: none;
            padding: 0;
            transform: none;

            input[type='submit'] {
              width: 100%;
              height: 2.75rem;
              background: ${theme.colors.variants.secondaryLight};
              color: ${theme.colors.variants.neutralLight4};
              margin-top: 1.25rem;
              &:hover {
                background: ${theme.colors.variants.secondaryDark};
              }
            }

            > svg {
              display: none;
            }
          }

          ${Wrapper} {
            display: block;
            margin-top: 1.25rem;
          }

          ${Field} {
            margin-top: 0.125rem;
            margin-right: 0;
          }

          ${Checkboxes} {
            text-align: left;
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple'
